<style scoped>
</style>

<template>
    <ButtonTopbar buttonText="Créer une discipline" @onTopbarButtonClicked="displayCreateDisciplineModal">
        <template v-slot:iconBefore>
            <font-awesome-icon :icon="['fas', 'plus']" />
        </template>
    </ButtonTopbar>
    <Aggrid
        apiRoute="discipline/list"
        :apiParams="filter"
        :columnDefs="columnDefs" 
        :frameworkComponents="frameworkComponents"
        :context="context"
        aggridHeightSubstract="100px"
    >
        <template v-slot:filter>
            <BaseCheckbox label="Afficher les disciplines supprimées" @onChange="onDeletedFilterChange"/>
            <div>
                <BaseInput v-model="search" label="Rechercher une discipline" type="text" name="search" @onChange="onSearchInputChange">
                    <template v-slot:iconBefore>
                        <font-awesome-icon :icon="['fas', 'search']" />
                    </template>
                </BaseInput>
            </div>
        </template>
    </Aggrid>
    <Modal v-show="displayModalCreateOrEditDiscipline === true">
        <template v-slot:modalIcon>
            <font-awesome-icon :icon="['fas', createOrEditMode === 'create' ? 'plus' : 'edit']" />
        </template>
        <template v-slot:modalTitle>
            <div v-show="createOrEditMode === 'create'">CRÉER UNE DISCIPLINE</div>
            <div v-show="createOrEditMode === 'edit'">MODIFIER UNE DISCIPLINE</div>
        </template>
        <template v-slot:modalContent>
            <form>
                <div>
                    <BaseInput
                        v-model="discipline.description"
                        label="Description"
                        type="text"
                        name="description"
                        validator="text"
                        :error="formErrors?.description?.error?.message"
                        @onChange="onFormInputChange"
                        :displayError="displayError"
                        :disabled="discipline.isSystem"
                    />
                </div>
                <div>
                    <BaseInput 
                        v-model="discipline.shortDescription"
                        label="Description courte"
                        type="text"
                        name="shortDescription"
                        validator="text"
                        maxLength="6"
                        :error="formErrors?.shortDescription?.error?.message"
                        @onChange="onFormInputChange"
                        :displayError="displayError"
                    />
                </div>
                <div>
                    <BaseInput 
                        v-model="discipline.defaultDuration"
                        label="Durée par défaut (½ journée)"
                        type="number"
                        name="defaultDuration"
                        validator="number"
                        :error="formErrors?.defaultDuration?.error?.message"
                        @onChange="onFormInputChange"
                        :displayError="displayError"
                    />
                </div>
            </form>
        </template>
        <template v-slot:modalButtons>
            <BaseButton class="white-button" @click="closeModal" buttonText="Fermer">
                <template v-slot:iconBefore>
                    <font-awesome-icon :icon="['fas', 'times']" />
                </template>
            </BaseButton>
            <BaseButton class="orange-button" @click="submitModal" buttonText="Valider">
                <template v-slot:iconBefore>
                    <font-awesome-icon :icon="['fas', 'check']" />
                </template>
            </BaseButton>
        </template>
    </Modal>
    <Dialog ref="dialog" />
</template>

<script>
    import ButtonTopbar from '../../components/Topbar/ButtonTopbar'
    import BaseButton from '../../components/Base/BaseButton'
    import BaseInput from '../../components/Base/BaseInput'
    import BaseCheckbox from '../../components/Base/BaseCheckbox'
    import Aggrid from '../../components/Aggrid/Aggrid'
    import ListDisciplinesActionButtonsRenderer from './ListDisciplinesActionButtonsRenderer'
    import Modal from '../../components/App/Modal'
    import Dialog from '../../components/App/Dialog'
    import axios from 'axios'
    import { formatPrice } from '../../utils/formatter'

    export default {
        name: 'Disciplines',
        components: {
            ButtonTopbar,
            BaseButton,
            BaseInput,
            BaseCheckbox,
            Aggrid,
            Modal,
            Dialog
        },
        data() {
            return {
                columnDefs: [
                    { field: 'description', headerName : `Description`, flex: 1, lockPosition: true },
                    { field: 'shortDescription', headerName : `Description courte`, flex: 1, lockPosition: true },
                    { field: 'defaultDuration', headerName : `Durée par défaut (½ journée)`, flex: 1, lockPosition: true, filter: 'agNumberColumnFilter' },
                    { headerName : '', width: 130, cellRenderer: 'ListDisciplinesActionButtonsRenderer', suppressMenu: true}
                ],
                frameworkComponents: {
                    ListDisciplinesActionButtonsRenderer: ListDisciplinesActionButtonsRenderer
                },
                context: null,
                displayModalCreateOrEditDiscipline: false,
                createOrEditMode: null,
                search: '',
                searchTimeout: null,
                discipline: null,
                filter: {
                    deleted: false,
                    search: ''
                },
                displayError: false,
                formErrors: {}
            }
        },
        beforeMount() {
            this.context = {
                componentParent: this
            };

            this.resetDiscipline();
        },
        methods: {
            closeModal() {
                this.resetDiscipline();
                this.displayModalCreateOrEditDiscipline = false;
                this.displayError = false;
            },
            submitModal() {
                if (this.createOrEditMode === 'create') {
                    this.createDiscipline();
                } else if (this.createOrEditMode === 'edit') {
                    this.editDiscipline();
                }
            },
            displayCreateDisciplineModal() {
                this.createOrEditMode = 'create';
                this.resetDiscipline();
                this.displayModalCreateOrEditDiscipline = true;
            },
            displayEditDisciplineModal(discipline) {
                this.createOrEditMode = 'edit';
                this.discipline = JSON.parse(JSON.stringify(discipline));
                this.displayModalCreateOrEditDiscipline = true;
            },
            resetDiscipline() {
                this.discipline = {
                    description: null,
                    shortDescription: null,
                    defaultDuration: null,
                    isSystem: null
                };
            },
            formateDiscipline(discipline) {
                discipline = JSON.parse(JSON.stringify(discipline));
                discipline.defaultDuration = parseInt(this.discipline.defaultDuration);
                return discipline;
            },
            createDiscipline() {
                if (!this.checkForm()) return;

                axios
                .post('discipline/create', this.formateDiscipline(this.discipline), { 
                    toastSuccessMessage: `Discipline créée`, 
                    toastError: true, 
                    showPreloader: true 
                })
                .then(() => {
                    this.closeModal();
                    this.emitter.emit('ag-grid-reload')
                })
                .catch(() => {});
            },
            editDiscipline() {
                if (!this.checkForm()) return;

                axios
                .put('discipline/update', this.formateDiscipline(this.discipline), { 
                    toastSuccessMessage: `Modification effectuée`, 
                    toastError: true, 
                    showPreloader: true 
                })
                .then(() => {
                    this.closeModal();
                    this.emitter.emit('ag-grid-reload')
                })
                .catch(() => {});
            },
            deleteOrEnableDiscipline(discipline) {
                this.$refs.dialog.show({
                    type: 'confirm',
                    title: 'Confirmation',
                    message: `Êtes-vous sûr de vouloir ${discipline.deleted ? 'ré-activer' : 'supprimer'} cette discipline ?`
                }).then(() => {
                    axios
                    .put('discipline/changeDeletedDiscipline/' + discipline.id, {
                        deleted: !discipline.deleted
                    }, { 
                        toastSuccessMessage: `Modification effectuée`, 
                        toastError: true, 
                        showPreloader: true 
                    })
                    .then(() => {
                        this.emitter.emit('ag-grid-reload')
                    });
                })
                .catch(() => {});
            },
            onFormInputChange(input) {
                if (input.error?.message !== null) {
                    this.formErrors[input.name] = input;
                } else {
                    delete this.formErrors[input.name];
                }
            },
            checkForm() {
                this.displayError = true;

                return Object.keys(this.formErrors).length === 0;
            },
            onSearchInputChange(input) {
                if(this.searchTimeout !== null) {
                    clearTimeout(this.searchTimeout);
                }
                
                this.searchTimeout = setTimeout(() => {
                    this.filter.search = input.value
                },250)
            },
            onDeletedFilterChange(deleted) {
                this.filter.deleted = deleted;
            }
        }
    }
</script>