<template>
    <div class="container-action-button" v-if="params.data">
        <BaseButton v-if="!this.params.data.isSystem" @click="onDeleteOrEnableDiscipline" buttonText="" buttonClass="orange-button" :title="this.params.data.deleted ? 'Restaurer' : 'Supprimer'">
            <template v-slot:iconBefore>
                <font-awesome-icon :icon="this.params.data.deleted ? ['fas', 'trash-restore'] : ['fas', 'trash']" />
            </template>
        </BaseButton>
        <BaseButton @click="onEditDiscipline" buttonText="" buttonClass="blue-button" title="Modifier">
            <template v-slot:iconBefore>
                <font-awesome-icon :icon="['fas', 'pen']" />
            </template>
        </BaseButton>
    </div>
</template>

<script>
    import BaseButton from '../../components/Base/BaseButton.vue';
    import * as Vue from "vue";
    import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

    export default {
        name: 'ListDisciplinesActionButtonsRenderer',
        components: {
            BaseButton: Vue.shallowRef(BaseButton),
            FontAwesomeIcon: Vue.shallowRef(FontAwesomeIcon)
        },
        methods: {
            onDeleteOrEnableDiscipline() {
                this.params.context.componentParent.deleteOrEnableDiscipline(this.params.data);
            },
            onEditDiscipline() {
                this.params.context.componentParent.displayEditDisciplineModal(this.params.data);
            }
        }
    }        
</script>